"use strict";

// Load styles, for Webpack CSS Tree Shaking.
import "./style.scss";

import MmenuLight from "mmenu-light";

document.addEventListener('DOMContentLoaded', function () {
    const headerWrapper = document.querySelector('.header__wrapper');
    const nav = document.querySelector('.wp-block-navigation');
    const searchBar = document.querySelector('.header__search');
    const socialLinks = document.querySelector('.header__social');

    // Create nav wrapper and append to header
    const navWrapper = document.createElement('div');
    navWrapper.classList.add('wp-block-nav__wrapper');
    headerWrapper.append(navWrapper);
    navWrapper.append(nav);

    // Create mobile nav icon
    const mobileToggle = document.createElement('div');
    const mobileToggleBar = document.createElement('div');
    mobileToggle.classList.add('mobile-toggle');
    mobileToggleBar.classList.add('mobile-toggle__bar');
    mobileToggle.append(mobileToggleBar.cloneNode(true));
    mobileToggle.append(mobileToggleBar.cloneNode(true));
    mobileToggle.append(mobileToggleBar.cloneNode(true));
    headerWrapper.append(mobileToggle);

    // Check if user has scrolled past a certain point, if it has apply styles and update the logo
    window.addEventListener('scroll', function() {
       const scrollTop = window.scrollY;
       
        if (scrollTop >= 100) {
            headerWrapper.classList.add('has-scrolled');
        } else {
            headerWrapper.classList.remove('has-scrolled');
        }
    });

    /**
     * Set up mobile nav
     */
    let myMenu = document.querySelector('.wp-block-nav__wrapper');
    const menu = new MmenuLight( myMenu, "(max-width: 992px)");
    const navigator = menu.navigation({
        title: '',
    });
    const drawer = menu.offcanvas();
    drawer.wrapper.classList.remove('mm-ocd--left');

    /**
     * Mobile Menu Toggle
     */
    if (mobileToggle) {
        mobileToggle.addEventListener('click', (event) => {
            event.preventDefault();
            moveNav();

            mobileToggle.classList.toggle('show-mobile-menu');
            if (mobileToggle.classList.contains('show-mobile-menu')) {
                drawer.open();
            } else {
                drawer.close();
            }
        });
    }

    /**
     * Set up Mobile Menu top style
     */
    function moveNav() {
        // Get relevant elements and their heights
        const header = document.querySelector('header.wp-block-template-part');
        const headerHeight = header ? header.offsetHeight : 0;
        const headerOffset = header.style.top ? parseInt(header.style.top, 10) : 0;
        const wpadminbar = document.querySelector('#wpadminbar');
        const wpadminbarHeight = wpadminbar ? wpadminbar.offsetHeight : 0;
        let top = (window.innerWidth < 600 && window.scrollY === 0 ) ? ( headerHeight + wpadminbarHeight ) + (headerOffset - 1)  : ( headerHeight ) + (headerOffset - 1);
        const mobileNav = document.querySelector('.mm-ocd');

        mobileNav.style.top = `${top}px`
    }
    window.addEventListener('load', () => {
        moveNav();
    });

    // Clone and append search and social links to Navigation Links
    const mobileNavWrapper = document.querySelector('.mm-ocd__content');
    const clonedSearchBar = searchBar.cloneNode(true);
    const clonedSocialLinks = socialLinks.cloneNode(true);
    mobileNavWrapper.prepend(clonedSearchBar);
    mobileNavWrapper.appendChild(clonedSocialLinks);

    // Make social links last child on window resize
    window.addEventListener('resize', () => {
        const windowWidth = window.innerWidth;

        if (windowWidth <= 991) {
            if (clonedSocialLinks !== clonedSocialLinks.parentElement.lastElementChild) {
                mobileNavWrapper.appendChild(clonedSocialLinks);
            }
        }
    });
});