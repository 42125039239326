/**
 * On load, set the announcement bar height variable. Update it on resize to
 * ensure it is always correct.
 */
document.addEventListener('DOMContentLoaded', function() {
	/**
	 * When the user scrolls down, hide the announcement bar. Have it appear when
	 * you scroll up.
	 */
	window.addEventListener('scroll', function() {
		setHeaderTop();
	});

	function setHeaderTop() {
		const announcementBar = document.getElementById('branda_content_header');
		const siteHeader = document.querySelector('.wp-site-blocks > header.wp-block-template-part');

		if (!announcementBar || !siteHeader) {
			return;
		}

		const announcementBarHeight = announcementBar.offsetHeight;
		const adminBar = document.querySelector('#wpadminbar');
		const adminBarHeight = adminBar ? adminBar.offsetHeight : 0;
		const offsetHeight = adminBar ? announcementBarHeight - adminBarHeight : announcementBarHeight;
		const windowWidth = window.innerWidth;
		const scrollTop = window.scrollY || document.documentElement.scrollTop;
		const scrollDirection = (scrollTop > this.lastScrollTop) ? 'down' : 'up';
		this.lastScrollTop = scrollTop;

		if (scrollDirection === 'down') {
			siteHeader.style.top = windowWidth > 600 ? `-${offsetHeight}px` : `-${announcementBarHeight}px`;
		}
		if (scrollDirection === 'up') {
			siteHeader.style.top = windowWidth > 600 ? `${adminBarHeight}px` : 0;
		}
	}
	window.addEventListener('load', () => {
        setHeaderTop();
    });
});
