/**
 * Scripts for the Simple Sitemap plugin
 */
document.addEventListener('DOMContentLoaded', function () {
	// Search for all anchor tags starting with the text "Thank You"
	const links = document.querySelectorAll('a');
	links.forEach(function (link) {
		if (link.textContent.startsWith('Thank You')) {
			// If found, add a class to the link
			link.classList.add('thank-you-link');
		}
	});
});
